import matchtitle from '../../components/matchtitle';

import {
	Message
} from 'element-ui'
import {
	handlePageQueryTeams,
	handlePageQueryAccounts,
	handlePageQuerySignupPlayersArchives,
	handlePageRealNameAuthDetailsTable,
	handlePageRealNameAuthSummaryTable,
	handleModifyTeamCode,
	handleControlTeamSignup,
	handleControlMatchState,
	handleQueryTeamOfMatch,
	handleQueryGroupsettingByMatchId,
	handleDownloadTeam,
	handleDownloadPlayerArchives,
	handleDownloadRealNameAuthDetailsTable,
	handleDownloadRealNameAuthSumTable,
	handleBatchDeleteDelegationTeams,
	handleQueryHandRealNameAuthInfo,
	handleManagerHandRealNameAuth
} from '@index/api/mymatchs/teammanage';
export default {
	name: 'teammanage',
	components: {
		matchtitle

	},
	data() {
		return {
			queryAccountForm: { signupStatus: '' },
			havAuth: false,
			countPlayers: 0,
			countGroups: 0,
			sumSignupFee: 0,
			groups: [],
			matchTeams: [],
			pageSizeTeam: 30,
			currPageTeam: 1,
			totalCountTeam: 0,
			queryFormTeam: {},
			form: {},
			tableDataTeam: [],
			matchId: sessionStorage.getItem("currMatchId"),
			tableData: [],
			modifyCodeDialogvisible: false,
			modifyTeamCodeForm: {},
			pageSizeAccount: 30,
			currPageAccount: 1,
			totalCountAccount: 0,
			queryFormAccount: {},
			tableDataAccount: [],

			pageSizeArchives: 30,
			currPageArchives: 1,
			totalCountArchives: 0,
			queryFormArchives: {},
			tableDataArchives: [],


			pageSizeAuthDetails: 30,
			currPageAuthDetails: 1,
			totalCountAuthDetails: 0,
			tableDataAuthDetails: [],


			pageSizeSum: 30,
			currPageSum: 1,
			totalCountSum: 0,
			tableDataSum: [],
			setSignupStatusDialogVisible: false,
			setSignupStatusForm: {},
			setModifyTeamCodeDialogVisible: false,
			setModifyTeamCodeForm: {},
			currPageIndex: '0',
			queryRealnameAuthForm: {},
			realNameAuthStatusList: [{ value: '0', label: '未认证' }, { value: '1', label: '已认证' }, { value: '2', label: '认证失败' }],
			setMatchStateDialogVisible: false,
			setMatchStateForm: { state: '' },
			realNameAuthDialogForm: { state: '2' },
			setRealNameAuthDialogVisible: false,



		};
	},
	watch: {

	},
	created() {
		this.pageQueryTeam();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
		
	},
	methods: {		
		initAuth(havAuth) {
			this.havAuth = havAuth;
			this.havAuth = true;
		},
		handleSizeChangeTeam(pageSize) { //改变页面大小
			this.pageSizeTeam = pageSize;
			this.pageQueryTeam();
		},
		handleCurrentChangeTeam(currPage) { //跳转到某一页
			this.currPageTeam = currPage;
			this.pageQueryTeam();
		},
		fastQueryTeam() {
			this.currPageTeam = 1;
			this.pageQueryTeam();
		},
		pageQueryTeam() {
			handlePageQueryTeams({
				pageSize: this.pageSizeTeam,
				currPage: this.currPageTeam,
				name: this.queryFormTeam.name,
				matchId: this.matchId,
			}).then(res => {
				if (res.status == 200) {
					this.tableDataTeam = res.data;
					this.currPageTeam = res.currPage;
					this.pageSizeTeam = res.pageSize;
					this.totalCountTeam = res.totalCount;
					this.countPlayers = res.extendData.countPlayers;
					this.countGroups = res.extendData.countGroups;
					this.sumSignupFee = res.extendData.sumSignupFee;
					this.$nextTick(() => {
						this.$refs.table1.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		controlTeamSignup(id, signupAuth) { //控制代表队报名
			handleControlTeamSignup({
				id: id,
				matchId: this.matchId,
				signupAuth: signupAuth,
			}).then(res => {
				this.setSignupStatusDialogVisible = false;
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.pageQueryTeam();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}

			})
		},
		modifyTeamCode(id) { //修改代表队编码
			handleModifyTeamCode({
				id: id,
				teamCode: this.modifyTeamCodeForm.teamCode
			}).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.$refs[id].doClose();
					this.modifyTeamCodeForm = {};
					this.pageQueryTeam();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		closeModifyCodeDialog(rowId) { //关闭修改编码对话框
			this.$refs[rowId].doClose()
		},
		//*****************************************************************************************(账号管理)
		handleSizeChangeAccount(pageSize) { //改变页面大小
			this.pageSizeAccount = pageSize;
			this.pageQueryAccount();
		},
		handleCurrentChangeAccount(currPage) { //跳转到某一页
			this.currPageAccount = currPage;
			this.pageQueryAccount();
		},
		fastQueryAccount() {
			this.currPageAccount = 1;
			this.pageQueryAccount();
		},
		pageQueryAccount() {
			handlePageQueryAccounts({
				pageSize: this.pageSizeAccount,
				currPage: this.currPageAccount,
				signupStatus: this.queryAccountForm.signupStatus,
				matchId: this.matchId,
			}).then(res => {
				if (res.status == 200) {
					this.tableDataAccount = res.data;
					this.currPageAccount = res.currPage;
					this.pageSizeAccount = res.pageSize;
					this.totalCountAccount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table2.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		witchType(tab) { //切换类型
			switch (tab.name) {
				case "0": //代表队管理
					this.fastQueryTeam();
					break;
				case "1": //账号管理
					this.fastQueryAccount();
					break;
				case "2": //选手档案
					this.fastQueryArchives();
					this.queryTeamOfMatch();
					this.queryGroupsettingByMatchId();
					break;
				case "3": //实名认证明细表
					this.pageQueryAuthDetails();
					break;
				case "4": //实名认证汇总表
					this.pageQuerySum();
					break;
			}
		},
		controlMatchState(id, state) { //控制比赛（允许比赛、禁止比赛）
			handleControlMatchState({
				id: id,
				state: state,
				matchId: this.matchId
			}).then(res => {
				if (res.status == 200) {
					this.setMatchStateDialogVisible = false;
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.pageQueryAccount();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		//选手档案选手档案选手档案选手档案选手档案选手档案**************************************************
		handleSizeChangeArchives(pageSize) { //改变页面大小
			this.pageSizeArchives = pageSize;
			this.pageQueryArchives();
		},
		handleCurrentChangeArchives(currPage) { //跳转到某一页
			this.currPageArchives = currPage;
			this.pageQueryArchives();
		},
		fastQueryArchives() {
			this.currPageArchives = 1;
			this.pageQueryArchives();
		},
		pageQueryArchives() { //分页查询报名选手档案
			var reqestParams = {
				...this.queryFormArchives
			};
			reqestParams.matchId = this.matchId;
			reqestParams.pageSize = this.pageSizeArchives;
			reqestParams.currPage = this.currPageArchives;
			handlePageQuerySignupPlayersArchives(reqestParams).then(res => {
				if (res.status == 200) {
					this.tableDataArchives = res.data;
					this.currPageArchives = res.currPage;
					this.pageSizeArchives = res.pageSize;
					this.totalCountArchives = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table3.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		queryTeamOfMatch() { //查询比赛的代表队
			handleQueryTeamOfMatch(this.matchId).then(res => {
				if (res.status == 200) {
					this.matchTeams = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		queryGroupsettingByMatchId() { //根据赛事id查询组别(下拉查询)
			handleQueryGroupsettingByMatchId(this.matchId).then(res => {
				if (res.status == 200) {
					this.groups = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		//分页查询实名认证明细表*****************************************
		handleSizeChangeAuthDetails(pageSize) { //改变页面大小
			this.pageSizeAuthDetails = pageSize;
			this.pageQueryAuthDetails();
		},
		handleCurrentChangeAuthDetails(currPage) { //跳转到某一页
			this.currPageAuthDetails = currPage;
			this.pageQueryAuthDetails();
		},
		pageQueryAuthDetails() {
			handlePageRealNameAuthDetailsTable({
				pageSize: this.pageSizeAuthDetails,
				currPage: this.currPageAuthDetails,
				realNameAuthStatus: this.queryRealnameAuthForm.realNameAuthStatus,
				con: this.queryRealnameAuthForm.con,
				matchId: this.matchId,
			}).then(res => {
				if (res.status == 200) {
					this.tableDataAuthDetails = res.data;
					this.currPageAuthDetails = res.currPage;
					this.pageSizeAuthDetails = res.pageSize;
					this.totalCountAuthDetails = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table4.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		RefreshRealAuthDetailsTable() {
			this.currPageAuthDetails = 1;
			this.pageQueryAuthDetails();
		},
		//分页查询实名认证汇总表***********************************
		handleSizeChangeSum(pageSize) { //改变页面大小
			this.pageSizeSum = pageSize;
			this.pageQuerySum();
		},
		handleCurrentChangeSum(currPage) { //跳转到某一页
			this.currPageSum = currPage;
			this.pageQuerySum();
		},
		pageQuerySum() {
			handlePageRealNameAuthSummaryTable({
				pageSize: this.pageSizeSum,
				currPage: this.currPageSum,
				matchId: this.matchId,
			}).then(res => {
				if (res.status == 200) {
					this.tableDataSum = res.data;
					this.currPageSum = res.currPage;
					this.pageSizeSum = res.pageSize;
					this.totalCountSum = res.totalCount;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		RefreshRealAuthTable() {
			this.currPageSum = 1;
			this.pageQuerySum();
		},
		downloadTeam() { //下载代表队		
			this.queryFormTeam.matchId = this.matchId;
			handleDownloadTeam(this.queryFormTeam).then(res => {
				var fileName = "代表队.xlsx";
				var blob = new Blob([res], {
					type: "application/octet-stream"
				});
				if (window.navigator.msSaveOrOpenBlob) { //msSaveOrOpenBlob方法返回bool值

					navigator.msSaveBlob(blob, fileName); //本地保存
				} else {

					var link = document.createElement('a'); //a标签下载
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					window.URL.revokeObjectURL(link.href);
				}
			});
		},
		downloadPlayerArchives() { //下载选手档案
			this.queryFormArchives.matchId = this.matchId;
			handleDownloadPlayerArchives(this.queryFormArchives).then(res => {
				var fileName = "选手档案.xlsx";
				var blob = new Blob([res], {
					type: "application/octet-stream"
				});
				if (window.navigator.msSaveOrOpenBlob) { //msSaveOrOpenBlob方法返回bool值

					navigator.msSaveBlob(blob, fileName); //本地保存
				} else {
					var link = document.createElement('a'); //a标签下载
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					window.URL.revokeObjectURL(link.href);
				}
			});
		},
		downloadRealNameAuthDetailsTable() { //下载实名认证明细表
			handleDownloadRealNameAuthDetailsTable(this.matchId).then(res => {
				var fileName = "实名认证明细表.xlsx";
				var blob = new Blob([res], {
					type: "application/octet-stream"
				});
				if (window.navigator.msSaveOrOpenBlob) { //msSaveOrOpenBlob方法返回bool值

					navigator.msSaveBlob(blob, fileName); //本地保存
				} else {
					var link = document.createElement('a'); //a标签下载
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					window.URL.revokeObjectURL(link.href);
				}
			});
		},
		downloadRealNameAuthSumTable() { //下载实名认证汇总表
			handleDownloadRealNameAuthSumTable(this.matchId).then(res => {
				var fileName = "名认证汇总表.xlsx";
				var blob = new Blob([res], {
					type: "application/octet-stream"
				});
				if (window.navigator.msSaveOrOpenBlob) { //msSaveOrOpenBlob方法返回bool值

					navigator.msSaveBlob(blob, fileName); //本地保存
				} else {
					var link = document.createElement('a'); //a标签下载
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					window.URL.revokeObjectURL(link.href);
				}
			});
		}
		, modifyTeamCodeEvent(id, teamCode) {
			this.setModifyTeamCodeDialogVisible = true;
			this.setModifyTeamCodeForm = { id: id, teamCode: teamCode };
		}
		, modifySignupAuthEvent(id, signupAuth) {
			this.setSignupStatusDialogVisible = true;
			this.setSignupStatusForm = { id: id, signupAuth: signupAuth };
		},
		submitSignupStatusForm() {
			this.controlTeamSignup(this.setSignupStatusForm.id, this.setSignupStatusForm.signupAuth);
		},
		submitTeamCodeForm() {
			handleModifyTeamCode({
				id: this.setModifyTeamCodeForm.id,
				teamCode: this.setModifyTeamCodeForm.teamCode,
				matchId: this.matchId
			}).then(res => {
				this.setModifyTeamCodeDialogVisible = false;
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					// this.$refs[id].doClose();
					// this.modifyTeamCodeForm = {};
					this.pageQueryTeam();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},

		selectPage(index) {
			this.currPageIndex = index;
			switch ((index + "")) {
				case "0": //代表队管理
					this.fastQueryTeam();
					break;
				case "1": //账号管理
					this.fastQueryAccount();
					break;
				case "2": //选手档案
					this.fastQueryArchives();
					this.queryTeamOfMatch();
					this.queryGroupsettingByMatchId();
					break;
				case "3": //实名认证明细表
					this.pageQueryAuthDetails();
					break;
			}
		},
		setMatchStateEvent(id, state) {
			this.setMatchStateDialogVisible = true;
			this.setMatchStateForm = { id: id, state: state };
		},
		submitMatchStateForm() {
			this.controlMatchState(this.setMatchStateForm.id, this.setMatchStateForm.state);
		},
		selectSignStatusEvent(event) {
			this.fastQueryAccount();
		},
		deleteTeam(id) {
			handleBatchDeleteDelegationTeams({ matchId: this.matchId, teamIds: [id] }).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.pageQueryTeam();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		closeRealNameAuthDialogEvent() {
			this.setRealNameAuthDialogVisible = false;
			this.realNameAuthDialogForm = { state: '2' };
		},
		queryHandRealNameAuthInfo(playerId) {
			this.realNameAuthDialogForm = { state: '2' };
			this.setRealNameAuthDialogVisible = true;
			handleQueryHandRealNameAuthInfo({ matchId: this.matchId, playerId: playerId }).then(res => {
				this.realNameAuthDialogForm = res.data;
			})

		},
		managerHandRealNameAuth() {
			handleManagerHandRealNameAuth({ matchId: this.matchId, playerId: this.realNameAuthDialogForm.playerId }).then(res => {
				if (res.status == 200) {
					this.setRealNameAuthDialogVisible = false;
					this.pageQueryAuthDetails();
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.pageQueryTeam();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		}
	}
}
import request from '@index/utils/request'

export function handlePageQueryTeams(data) { //  分页查询代表队管理
	return request({
		url: 'delegationteam/pageQueryTeams',
		method: 'post',
		data
	})
}



export function handlePageQueryAccounts(data) { // 分页查询账号管理
	return request({
		url: 'delegationteam/pageQueryAccounts',
		method: 'post',
		data
	})
}


export function handlePageQuerySignupPlayersArchives(data) { // 分页查询报名选手档案
	return request({
		url: 'delegationteam/PageQuerySignupPlayersArchives',
		method: 'post',
		data
	})
}





export function handlePageRealNameAuthDetailsTable(data) { // 分页查询实名认证明细表
	return request({
		url: 'delegationteam/pageRealNameAuthDetailsTable',
		method: 'post',
		data
	})
}



export function handlePageRealNameAuthSummaryTable(data) { // 分页查询实名认证汇总表
	return request({
		url: 'delegationteam/pageRealNameAuthSummaryTable',
		method: 'post',
		data
	})
}


export function handleModifyTeamCode(data) { // 修改代表队编码
	return request({
		url: 'delegationteam/modifyTeamCode',
		method: 'post',
		data
	})
}

export function handleControlTeamSignup(data) { // 控制代表队报名
	return request({
		url: 'delegationteam/controlTeamSignup',
		method: 'post',
		data
	})
}

export function handleControlMatchState(data) { // 控制比赛（允许比赛、禁止比赛）
	return request({
		url: 'joinmatch/controlMatchState',
		method: 'post',
		data
	})
}


export function handleQueryTeamOfMatch(matchId) { // 查询比赛的代表队
	return request({
		url: 'onlinesignuptable/queryTeamOfMatch',
		method: 'post',
		params: {
			matchId: matchId
		}
	})
}


export function handleQueryGroupsettingByMatchId(matchId) { // 根据赛事id查询组别(下拉查询)
	return request({
		url: 'groupsetting/queryGroupsettingByMatchId',
		method: 'post',
		params: {
			matchId: matchId
		}
	})
}



export function handleDownloadTeam(data) { //下载代表队
	return request({
		url: 'delegationteam/downloadTeam',
		method: 'post',
		responseType: 'blob',
		data
	})
}


export function handleDownloadPlayerArchives(data) { //选手档案
	return request({
		url: 'delegationteam/downloadPlayerArchives',
		method: 'post',
		responseType: 'blob',
		data
	})
}



export function handleDownloadRealNameAuthDetailsTable(matchId) { //下载实名认证明细表
	return request({
		url: 'delegationteam/downloadRealNameAuthDetailsTable',
		method: 'post',
		responseType: 'blob',
		params: {
			matchId: matchId
		}
	})
}


export function handleDownloadRealNameAuthSumTable(matchId) { //下载实名认证汇总表
	return request({
		url: 'delegationteam/downloadRealNameAuthSumTable',
		method: 'post',
		responseType: 'blob',
		params: {
			matchId: matchId
		}
	})
}



export function handleBatchDeleteDelegationTeams(data) { // 批量删除代表队
	return request({
		url: 'delegationteam/batchDeleteDelegationTeams',
		method: 'post',
		data
	})
}

export function handleQueryHandRealNameAuthInfo(data) { // 查询手动实名认证信息
	return request({
		url: 'realnameauth/queryHandRealNameAuthInfo',
		method: 'post',
		data
	})
}

export function handleManagerHandRealNameAuth(data) { // 管理员手动实名认证
	return request({
		url: 'realnameauth/managerHandRealNameAuth',
		method: 'post',
		data
	})
}
